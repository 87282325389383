<template>
  <div>
    <el-container
      style="
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
      "
    >
      <!-- 顶部布局 -->
      <el-header
        class="d-flex align-items-center"
        style="background-color: #545c64"
      >
        <router-link :to="{ name: 'project_ProjectList' }">
          <el-button size="small" circle>返回项目列表</el-button>
        </router-link>

        <a
          class="h5 text-light mb-0"
          style="margin-right: auto; padding-left: 10px"
          >{{ project_name }}</a
        >
        <el-menu
          :default-active="toplist.active"
          mode="horizontal"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          @select="handleSelect"
        >
          <el-menu-item
            :index="index.toString()"
            v-for="(item, index) in toplist.list"
            :key="index"
            >{{ item.name }}</el-menu-item
          >

          <el-submenu index="99">
            <template #title>
              <el-avatar
                size="small"
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              ></el-avatar>
              {{ user.usealias }}
            </template>
            <el-menu-item index="99-1">修改</el-menu-item>
            <el-menu-item index="99-2">退出</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-header>
      <el-container style="height: 100%; padding-bottom: 60px">
        <!-- 侧边布局 -->
        <el-aside width="150px">
          <el-menu
            :default-active="slideMenusActive"
            background-color="#F0F8FF"
            @select="asideSelect"
            style="height: 100%; padding-bottom: 60px"
          >
            <el-menu-item
              :index="index.toString()"
              :key="index"
              v-for="(item, index) in slideMenus"
            >
              <span>{{ item.name }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <!-- 主布局 -->
        <el-main>
          <!-- 面包屑 -->
          <div
            class="border-bottom"
            style="padding: 20px; margin: -20px"
            v-if="bran.length > 0"
          >
            <el-breadcrumb separator=">>">
              <el-breadcrumb-item v-for="(item, index) in bran" :key="index">{{
                item.title
              }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <!-- 主内容 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      bran: [],
      project_ids: window.sessionStorage.getItem("project_ids"), //项目ID
      project_name: window.sessionStorage.getItem("project_name"),
      slideMenusActive: 0,
    };
  },
  created() {
    //初始化选中菜单
    this.__initNavBar();
    //生成菜单
    this.createNavBar();
    //面包屑导航
    this.getRouterBran();
  },
  watch: {
    //监视路由改变
    $route() {
      localStorage.setItem(
        "navActive",
        JSON.stringify({
          top: this.toplist.active || "0",
          left: this.slideMenusActive || "0",
        })
      );
      //面包屑导航
      this.getRouterBran();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      toplist: (state) => state.menu.toplist,
    }),
    slideMenus() {
      let item = this.toplist.list[this.toplist.active];
      return item ? item.submenu : [];
    },
  },
  methods: {
    //初始化选中菜单
    __initNavBar() {
      let r = localStorage.getItem("navActive");
      if (r) {
        r = JSON.parse(r);
        this.toplist.active = r.top;
        this.slideMenusActive = r.left;
      }
    },
    //生成菜单
    createNavBar() {
      this.axios
        .get("admin/ManagerMenus", {
          params: {
            project_id: this.project_ids,
            manager_id: this.user.id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.$store.commit("createNavBar", res.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //侧边布局
    asideSelect(key) {
      this.slideMenusActive = key;
      //跳转到指定页面
      this.$router.push({ name: this.slideMenus[key].pathname });
    },
    //顶部布局
    handleSelect(key) {
      if (key === "99-2") {
        //退出登录
        console.log("退出登录");
        return this.logout();
      }
      if (key === "99-1") {
        console.log("用户设置");
        return;
      }

      this.toplist.active = key;
      this.slideMenusActive = "0"; //默认左侧菜单栏，激活为第0个菜单
      let temprouter = this.toplist.list[key].submenu[0].pathname;
      this.$router.push({ name: temprouter });
    },
    //面包屑导航
    getRouterBran() {
      let b = this.$route.matched.filter((v) => v.name);
      let arr = [];
      b.forEach((v) => {
        if (v.name === "index" || v.name === "layout") {
          this.bran = [];
          return;
        }
        arr.push({
          name: v.name,
          path: v.path,
          title: v.meta.title,
        });
        if (arr.length > 0) {
          arr.unshift({ name: "index", path: "/index", title: "项目首页" });
        }
        this.bran = arr;
      });
    },

    //退出登录
    logout() {
      this.$router.push({ name: "login" });
      this.axios
        .post(
          "/admin/logout",
          {},
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )

        .then(() => {
          this.$state.commit("logout");
          //返回到登录页
          this.$router.push({ name: "login_index" });
          //跳转后台首页
          // this.$router.push({ name: "project_ProjectList" });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.homeabc {
  height: 100%;
  margin-top: 20px;
}
</style>
